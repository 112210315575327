import styled, { css } from 'styled-components/macro'

import { DotDivisor } from '../../components/DotDivisor/DotDivisor'

export const BlocksRow = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
`

export const LeftBlock = styled.div`
  max-width: 50%;
`

export const SubjectText = styled.div<{bold?: boolean}>`
  margin-bottom: 1rem;
  font-size: 14px;

  ${p => p.bold && css`
    font-weight: 700;
    text-transform: uppercase;
  `}
`

interface AlignedItemsProps {
  rtl?: boolean
}

export const AlignedItems = styled.div<AlignedItemsProps>`
  display: grid;
  align-items: flex-end;
  grid-template-columns: min-content min-content auto;
  ${p => p.rtl && css`
    grid-template-columns: auto min-content min-content;

    ${ItemRole} {
      text-align: left;
    }
  `}
`

export const LineItem = styled.div<{spaced?: number}>`
  display: contents;
  font-size: 14px;
  line-height: 1.125;

  > *, > ${DotDivisor} {
    margin-bottom: 0.35rem;
    
    ${p => p.spaced && css`
      margin-top: ${1.75 * p.spaced}rem !important;
    `}
  }

`

export const ItemRole = styled.strong<{uppercase?: boolean}>`
  font-weight: 700;
  text-align: right;
  white-space: nowrap;
  ${p => p.uppercase && css`
    text-transform: uppercase;
  `}
`

export const LineValue = styled.div`

  small {
    display: block;
    margin-top: -0.2em;
    margin-bottom: -0.65em;
    font-weight: 600;
    font-style: italic;
    line-height: 1;
  }

  .empty {
    color: #757575;
  }
`

export const RightBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
`

export const WatchtowerLabel = styled.div`
  margin-top: -1.75rem;
  margin-right: -1rem;
  margin-bottom: 0.35rem;
  color: #951426;
  font-size: 16px;
  font-weight: 700;

  &:after {
    content: '';
    display: inline-block;
    width: 60px;
    height: 5px;
    margin-left: 0.5rem;
    vertical-align: middle;
    background: #951426;
  }
`

export const SpecialOcasion = styled.div`
  flex: 1 0 100%;
  opacity: 0.6;
  margin-top: -1.75rem;
  padding: 2.75rem 0;
  font-size: 1.125rem;
  font-weight: 900;
  text-align: center;
  text-transform: uppercase;
`

export const BottomInfo = styled.div`
  position: absolute;
  bottom: 1rem;
  right: 1.5rem;
  font-style: italic;
  text-align: right;

  strong {
    color: #951426;
    font-style: normal;
    font-weight: 600;
    text-transform: uppercase;
  }

  svg {
    vertical-align: -0.125em;
  }
`
